// src/pages/PR.js

import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {
    formatDateTime24,
    formatDate,
    formatTime,
    computeDay,
    timeAgo,
    computeGrade,
    rowClassName
} from "../utils/formatting";
import { getLatestMatches } from "../api";
import MatchTable from "../components/MatchTable";

const cellStyle = { padding: "0.3rem 0.5rem" };

const PR = ({ clubName }) => {
    const [upcomingFixtures, setUpcomingFixtures] = useState([]);
    const [recentResults, setRecentResults] = useState([]);
    const [lastModified, setLastModified] = useState({ fixtures: null, results: null });
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchLatest();
    }, []);

    async function fetchLatest() {
        try {
            const data = await getLatestMatches();
            const now = new Date();
            const sevenDaysAhead = new Date();
            sevenDaysAhead.setDate(now.getDate() + 7);
            const sevenDaysAgo = new Date();
            sevenDaysAgo.setDate(now.getDate() - 7);

            setUpcomingFixtures(
                (data.fixtures || []).filter(f =>
                    new Date(f.dateAndTime) >= now &&
                    new Date(f.dateAndTime) <= sevenDaysAhead
                )
            );
            setRecentResults(
                (data.results || []).filter(r =>
                    new Date(r.dateAndTime) <= now &&
                    new Date(r.dateAndTime) >= sevenDaysAgo
                )
            );

            if (data.lastModified) {
                setLastModified({
                    fixtures: data.lastModified.fixtures,
                    results: data.lastModified.results,
                });
            }
            setLoading(false);
        } catch (error) {
            console.error("Error fetching latest data:", error);
            setLoading(false);
        }
    }

    if (loading) {
        return (
            <div className="container mt-4">
                <h2>Loading dashboard...</h2>
            </div>
        );
    }

    const fixtureColumns = [
        { header: "Grade", render: item => computeGrade(item) },
        { header: "Date", render: item => formatDate(item.dateAndTime) },
        { header: "Time", render: item => formatTime(item.dateAndTime) },
        { header: "Day", render: item => computeDay(item.dateAndTime) },
        { header: "Competition", render: item => item.competition || "-" },
        { header: "Home", render: item => item.homeTeam || "-" },
        { header: "Away", render: item => item.awayTeam || "-" },
        { header: "Venue", render: item => item.venue || "-" },
        { header: "Referee", render: item => item.referee || "-" }
    ];

    const resultColumns = [
        { header: "Grade", render: item => computeGrade(item) },
        { header: "Date", render: item => formatDate(item.dateAndTime) },
        { header: "Time", render: item => formatTime(item.dateAndTime) },
        { header: "Day", render: item => computeDay(item.dateAndTime) },
        { header: "Competition", render: item => item.competition || "-" },
        { header: "Home", render: item => item.homeTeam || "-" },
        { header: "Away", render: item => item.awayTeam || "-" },
        {
            header: "Score",
            render: item => (item.ftHome != null && item.ftAway != null)
                ? `${item.ftHome} - ${item.ftAway}`
                : `${item.homeScore || '-'} : ${item.awayScore || '-'}`,
            cellStyle: { whiteSpace: "nowrap", padding: "0.3rem 0.5rem" }
        },
        { header: "Result", render: item => item.result || "-" },
        { header: "Venue", render: item => item.venue || "-" },
        { header: "Referee", render: item => item.referee || "-" }
    ];

    const fixturesHome = upcomingFixtures.filter(f => f.homeTeam?.toLowerCase().includes(clubName.toLowerCase()));
    const fixturesAway = upcomingFixtures.filter(f =>
        !fixturesHome.includes(f) && f.awayTeam?.toLowerCase().includes(clubName.toLowerCase())
    );
    const fixturesOther = upcomingFixtures.filter(f =>
        !fixturesHome.includes(f) && !fixturesAway.includes(f)
    );

    return (
        <div className="container mt-4">
            <h3>PR for {clubName}</h3>
            <hr />

            <h4>Upcoming Fixtures (Next 7 Days)</h4>
            {lastModified.fixtures && (
                <h6 style={{ fontSize: "0.9rem" }}>
                    Last updated: {formatDateTime24(lastModified.fixtures)} ({timeAgo(lastModified.fixtures)})
                </h6>
            )}

            {fixturesHome.length > 0 && (
                <>
                    <h5 className="mt-4">{clubName} at Home</h5>
                    <MatchTable
                        columns={fixtureColumns}
                        data={fixturesHome}
                        cellStyle={cellStyle}
                        rowClassName={rowClassName}
                    />
                </>
            )}

            {fixturesAway.length > 0 && (
                <>
                    <h5 className="mt-4">{clubName} Away</h5>
                    <MatchTable
                        columns={fixtureColumns}
                        data={fixturesAway}
                        cellStyle={cellStyle}
                        rowClassName={rowClassName}
                    />
                </>
            )}

            {fixturesOther.length > 0 && (
                <>
                    <h5 className="mt-4">Other Fixtures</h5>
                    <MatchTable
                        columns={fixtureColumns}
                        data={fixturesOther}
                        cellStyle={cellStyle}
                        rowClassName={rowClassName}
                    />
                </>
            )}

            <hr />

            <h4>Recent Results (Last 7 Days)</h4>
            {lastModified.results && (
                <h6 style={{ fontSize: "0.9rem" }}>
                    Last updated: {formatDateTime24(lastModified.results)} ({timeAgo(lastModified.results)})
                </h6>
            )}
            <MatchTable
                columns={resultColumns}
                data={recentResults}
                cellStyle={cellStyle}
                rowClassName={rowClassName}
            />
        </div>
    );
};

export default PR;
