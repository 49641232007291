// Footer.js (example modification)
import React, { useEffect, useState } from "react";

const Footer = () => {
  const [version, setVersion] = useState("loading...");
  const isLocal = process.env.REACT_APP_IS_LOCAL === 'true';

  useEffect(() => {
    if (isLocal) {
      setVersion("local-dev"); // Or any other indicator
    } else {
      fetch("/version.json")
        .then((res) => res.json())
        .then((data) => {
          setVersion(data.buildTimestamp);
        })
        .catch(() => {
          setVersion("unknown");
        });
    }
  }, [isLocal]);

  return (
    <footer style={{ textAlign: "center", padding: "1rem", marginTop: "2rem" }}>
      Version: {version}
    </footer>
  );
};
export default Footer;